import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'

import Layout from 'gatsby-theme-kn/src/components/modules/Layout'
import DealerMap from 'gatsby-theme-kn/src/components/modules/DealerMap'

const Search = ({ location }) => {
  const [geolocation, setGeolocation] = useState(null)
  const [error, setError] = useState(null)

  const query = location.state && location.state.value
  const byInput = location.state?.byInput && location.state.byInput
  const byCoords = location.state?.byCoords && location.state.byCoords

  const onChange = ({ coords }) => {
    setGeolocation({
      lat: coords.latitude,
      lng: coords.longitude,
    })
  }
  const onError = (error) => {
    setError(error.message)
  }

  useEffect(() => {
    if (byCoords) {
      const geo = navigator.geolocation
      if (!geo) {
        setError('Geolocation is not supported')
        return
      }
      const watcher = geo.watchPosition(onChange, onError)
      return () => geo.clearWatch(watcher)
    }
  }, [byCoords])

  return (
    <Layout pageName="home">
      <Helmet>
        <title>Home Page</title>
      </Helmet>
      <div style={{ marginTop: '100px' }}>
        <DealerMap
          searchQuery={query}
          byInput={byInput}
          byCoords={byCoords}
          coords={geolocation}
          error={error}
        />
      </div>
    </Layout>
  )
}

export default Search
